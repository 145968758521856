import React from 'react'

import { Backdrop, CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface ConfirmDialogProps {
  modalOpen: boolean
  handleNo: () => void
  handleYes: () => void
  content: string
  title: string
  isLoading?: boolean
}

const ConfirmDialog = ({
  handleNo,
  handleYes,
  modalOpen,
  content,
  title,
  isLoading,
}: ConfirmDialogProps) => (
  <Dialog
    open={modalOpen}
    onClose={handleNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
        data-testid="dialog-message"
      >
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleNo} color="primary" data-testid="dialog-no">
        No
      </Button>
      <Button
        onClick={handleYes}
        color="primary"
        autoFocus
        data-testid="dialog-yes"
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmDialog
